import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { shallowEqual, useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';
import dayjs from 'dayjs';

import { providerItems } from 'features/slotsBetHistory/helpers/data';
import { formatNumberWithLimit } from 'shared/utils/formatNumber';

import './SlotsHistoryItem.scss';

const b = block('slots-history-item-desktop');

const SlotsHistoryItem = ({ item }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { currency } = useSelector(state => state.auth, shallowEqual);

  const { amount, date, provider, status, userId } = item;

  const icon = useMemo(
    () => providerItems.find(p => p?.value?.toLowerCase() === provider?.toLowerCase())?.icon || '',
    [provider],
  );

  return (
    <div className={b()}>
      <div className={b('top')}>
        <div className={b('item')}>
          <span className={b('item-title')}>{`${locale.userId}`}</span>
          <span className={b('item-value')}>{`${userId}`}</span>
        </div>

        <div className={b('item', { end: true })}>
          <span className={b('item-title')}>{`${locale.provider}`}</span>
          <span className={b('item-value')}>{`${provider}`}</span>
        </div>

        <div className={b('item')}>
          <span className={b('item-title')}>{`${locale.date}`}</span>
          <span className={b('item-value')}>{date}</span>
        </div>

        <div className={b('item')}>
          <span className={b('item-title')}>{locale.bet}</span>
          <span className={b('item-value')}>{`${formatNumberWithLimit(amount)}`}</span>
        </div>

        <div className={b('item')}>
          <span className={b('item-title', { end: true })}>{locale.outcome}</span>
          {status === 1 ? (
            <>
              <span className={b('win')}>{`${formatNumberWithLimit(amount)}`}</span>
            </>
          ) : (
            <span className={b('lose')}>{locale.lost}</span>
          )}
        </div>
      </div>
    </div>
  );
};

SlotsHistoryItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default SlotsHistoryItem;
