import { errorStatusMessage } from './errorStatusMessage';

export const locale = {
  ...errorStatusMessage,
  changeLang: 'Cambiar idioma',
  changePas: 'Cambiar la contraseña',
  logout: 'Cerrar sesión',
  other: 'Otro',
  successAuth: 'Autorización de éxito',
  notRules: 'Privilegios de inicio de sesión insuficientes',

  newPlayer: 'Nuevo jugador',
  userName: 'Nombre de usuario',
  password: 'Contraseña',
  createAccount: 'Crear una cuenta',
  /* '/user-create': 'Cash register - New agent', */
  '/user-create-1': 'Caja registradora - Nuevo jugador',
  '/user-create-2': 'Caja registradora - Nuevo agente',
  '/user-create-3': 'Caja registradora - Nuevo administrador',
  '/total': 'Transacciones de caja',
  '/transaction-history': 'Caja registradora - Historial de transacciones',
  '/': 'Menú',
  '/locale': 'Idiomas',
  '/user-edit': 'Caja registradora - Edición de usuario',
  '/change-pas': 'Caja registradora - Cambiar contraseña',
  '/cashier-jackpot': 'Caja registradora - Jackpot del cajero',
  '/users-list': 'Caja registradora - Saldos de tarjetas de usuario',
  '/totalBalance': 'Balance',
  '/tree': 'Árbol',
  userCardBalances: 'Saldos de las tarjetas de los usuarios',
  userCard: 'tarjeta de usuario',
  accountBalance: 'Saldo de la cuenta',
  nickname: 'Apodo',
  id: 'Id',
  betsMenu: 'Apuestas',
  balance: 'Balance',
  ban: 'Prohibición',
  banSuccess: 'Bloquear el éxito',
  unbanSuccess: 'Desbloquear éxito',
  unban: 'Desbanear',
  resetPassword: 'Restablecer la contraseña',
  resetSuccess: 'Restablecer contraseña con éxito',
  networkError: 'Error de red',
  createSuccess: 'Crear éxito',
  topUp: 'Recargar',
  page: 'Página',
  withdrawal: 'Retiro',
  topUpSuccess: 'Éxito en la recarga',
  withdrawalSuccess: 'Retiro exitoso',
  topUpWithdrawal: 'Recarga/Retiro',
  ok: 'De acuerdo',
  theNewPassword: 'la nueva contraseña',
  for: 'para',
  all: 'Todo',
  '/transaction-history': 'Historial de transacciones',
  startDate: 'Fecha de inicio',
  endingDate: 'Fecha de finalización',
  display: 'Mostrar',
  email: 'Correo electrónico',
  date: 'Fecha',
  amount: 'Cantidad',
  languageSelection: 'Selección de idioma',
  login: 'Acceso',

  betTypes: {
    all: 'Todos los tipos',
    bet: 'Apuesta',
    win: 'Ganar',
    returned: 'Devuelto',
    cashedOut: 'Sin efectivo',
    cashed_out: 'Sin efectivo',
    lost: 'Perdido',
    rollback: 'Retroceder',
    refund: 'Reembolso',
  },
  betType: 'Tipo de apuesta',
  selectPeriod: 'Seleccionar período',
  from: 'De',
  to: 'A',
  apply: 'Aplicar',
  userId: 'ID de usuario:',
  betTableHeads: {
    id: 'ID',
    type: 'Tipo',
    fromUserId: 'Desde la UD del usuario',
    toUserId: 'A la ID del usuario',
    value: 'Valor',
    currency: 'Divisa',
    note: 'Nota',
    createdAt: 'Creado en',
    description: 'Descripción',
    balanceBefore: 'Saldo antes',
  },
  noBets: 'Sin apuestas',

  cashRegister: 'Caja registradora',
  createUser: {
    1: 'Nuevo jugador',
    2: 'Nuevo agente',
    3: 'Nuevo administrador',
  },
  balances: {
    1: 'Saldos de tarjetas de usuario',
    2: 'Comprar saldos de tarjetas',
    3: 'Saldos de tarjetas de administrador',
  },
  cashiersTransactions: {
    1: 'Transacciones de caja',
    2: 'Transacciones de la tienda',
    3: 'Transacciones de administradores',
  },
  bets: {
    1: 'Apuestas',
    2: 'Apuestas',
    3: 'Apuestas',
  },
  partners: 'Socios',
  revenuBalances: 'Saldos de ingresos',
  transactionHistory: {
    1: 'Historial de transacciones',
    2: 'Historial de transacciones',
    3: 'Historial de transacciones',
  },
  cashierJackpot: {
    1: 'Bote de cajero',
    2: 'Bote de cajero',
    3: 'Bote de cajero',
    7: 'Bote de cajero',
  },
  totalBalance: {
    1: 'Balance',
    2: 'Balance',
    3: 'Balance',
    7: 'Balance',
  },
  tree: {
    1: 'Árbol',
    2: 'Árbol',
    3: 'Árbol',
    7: 'Árbol',
  },
  casinoHistory: {
    1: 'Historia del casino',
    2: 'Historia del casino',
    3: 'Historia del casino',
    7: 'Historia del casino',
  },
  casinoHistory2: 'Historia del casino',

  total: 'Total',
  term: 'Término',
  deposit: 'Depósito',
  withdraw: 'Retirar',
  cashPayment: 'Pago en efectivo',
  cashPayout: 'Pago en efectivo',
  search: 'Buscar',
  errorPassword:
    'La contraseña debe constar de: al menos 8 caracteres, al menos un número, letras mayúsculas y minúsculas.',
  noPass: '¡La contraseña no debe estar vacía!',
  dataUpdate: 'Los datos se actualizan una vez cada 12 horas. Por lo tanto, los datos del día actual pueden ser incorrectos.',
  treeMenu: 'Árbol',

  players: 'Jugadores',
  subusers: 'Subusuarios',

  jackpotWinnersList: 'Lista de ganadores del premio mayor',
  startAmount: 'Importe inicial',

  winnerID: 'ID del ganador',
  winDate: 'Fecha de victoria',
  amountOfTheWinningJackpot: 'Monto del premio mayor ganador',

  totalWithdrawal: 'Retiro total',
  totalTopUp: 'Depósito total',

  provider: 'Proveedor',
  gameType: 'Tipo de juego',

  newAgent1: 'Caja registradora - Nuevo jugador',
  newAgent2: 'Caja registradora - Nuevo agente',
  newAgent3: 'Caja registradora - Nuevo administrador',

  home: 'Hogar',
  changeLanguage: 'Cambiar idioma',
  changePassword: 'Cambiar la contraseña',
  changedPasswordDoNotMatch: 'Las contraseñas no coinciden',
  oldPassword: 'Contraseña anterior',
  newPassword: 'Nueva contraseña',
  confirmNewPassword: 'Confirmar nueva contraseña',
  enterOldPassword: 'Ingrese la contraseña anterior',
  enterNewPassword: 'Ingrese nueva clave',
  repeatNewPassword: 'Repita la nueva contraseña',
  save: 'Ahorrar',

  typeSomething: 'Escribe algo',

  userEdit: 'Edición de usuario',

  userId: 'ID de usuario',
  showResults: 'Mostrar resultados',
  bet: 'apuesta',
  outcome: 'Resultado',
  fail: 'fallar',

  role: {
    0: 'Jugador',
    1: 'Cajero',
    2: 'Administrador',
    3: 'Suadmin',
  },
  'La contraseña solo puede contener letras y números': 'La contraseña solo puede contener letras y números',
  error: 'Error',
  lost: 'Perdido',

  wantLogout: '¿Estás seguro de que quieres cerrar sesión?',
  yesLogout: 'Sí, cerrar sesión',
  noIStay: "No, me quedo aquí.",
  welcomeToPanel: '¡Bienvenido al panel de administración!',
  useLeftMenu: 'Use el menú de la izquierda para administrar usuarios',
  adminPanel: 'Panel de administrador',
  userData: 'Datos del usuario',

  withdrawalRequests: {
    1: 'Solicitudes de retiro',
    2: 'Solicitudes de retiro',
    3: 'Solicitudes de retiro',
    7: 'Solicitudes de retiro',
  },
  status: 'Estado',
  playerId: 'ID del jugador',
  phone: 'Teléfono',
  edit: 'Editar',
  request: 'Pedido',
  approve: 'Aprobar',
  decline: 'Rechazar',
  nameOrId: 'Escriba inicio de sesión o ID',
  username: 'Nombre de usuario',
  bank: {
    1: 'Banco',
    2: 'Banco',
    3: 'Banco',
    7: 'Banco',
  },
  pendingTopup: {
    1: 'Solicitudes de recarga pendientes',
    2: 'Solicitudes de recarga pendientes',
    3: 'Solicitudes de recarga pendientes',
    7: 'Solicitudes de recarga pendientes',
  },
  pendingWithdrawal: {
    1: 'Solicitudes de retiro pendientes',
    2: 'Solicitudes de retiro pendientes',
    3: 'Solicitudes de retiro pendientes',
    7: 'Solicitudes de retiro pendientes',
  },
  topUpRequests: {
    1: 'Solicitudes de recarga',
    2: 'Solicitudes de recarga',
    3: 'Solicitudes de recarga',
    7: 'Solicitudes de recarga',
  },

  createRequisite: 'Crear requisito',
  requisite: 'Requisito',
  create: 'Crear',
  removeRequisite: 'Quitar requisito',

  statuses: {
    0: 'pago sin procesar',
    1: 'Pago aceptado',
    2: 'Pago rechazado',
    3: 'Pago reembolsado',
    4: 'Error de pago',
  },
  allStatuses: 'Todos los estados',
  chooseBank: 'elegir banco',
  activeRequestsStatuses: {
    0: 'Creado',
    1: 'Pagado',
    2: 'Cancelado',
    3: 'Reembolsado',
    4: 'Error',
  },
  receipt: 'Recibo',
  paymentRequisite: 'Requisito de pago',
  paymentSystem: 'Sistema de pago',
  amountFrom: 'Importe desde',
  amountTo: 'Cantidad a',

  newTopUp: 'Nuevo recarga por usuario',
  newWithdrawal: 'Nuevo retiro por parte del usuario',
  newPaymentsPending: 'Tienes nuevos pagos pendientes',

  userBalance: 'Saldo del usuario',
  casino: 'Casino',
  liveCasino: 'Casino en vivo',
};
