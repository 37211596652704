import React, { useMemo, useCallback } from 'react';
import block from 'bem-cn';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SVG from 'components/SVG';
import { formatNumberWithLimit } from 'shared/utils/formatNumber';

import logoSVG from '../img/logo.svg';

import './Header.scss';

const b = block('header');

const Header = () => {
  const locale = useSelector(state => state.locale.locale);
  const { isAuth, balance, currency, name, id } = useSelector(state => state.auth);

  const balanceFormatted = formatNumberWithLimit(balance);

  return (
    <div className={b({ isAuth })}>
      {isAuth ? (
        <>
          <Link to="/" className={b('link')}>
            <SVG svgProps={{ svg: logoSVG }} className={b('logo')} />
          </Link>

          <div className={b('user-info')}>
            <div className={b('block')}>
              <p className={b('value')}>{name}</p>
              <p className={b('value', { big: true })}>{`${balanceFormatted} ${currency}`}</p>
            </div>

            <div className={b('block')}>
              <p className={b('label')}>ID</p>
              <p className={b('value', { big: true })}>{id}</p>
            </div>
          </div>
        </>
      ) : (
        <h3 className={b('title')}>{locale.role[2]}</h3>
      )}
    </div>
  );
};

export default withRouter(Header);
