import AuthApi from './AuthApi';
import UserApi from './UserApi';
import TotalApi from './TotalApi';
import TotalBalanceApi from './TotalBalanceApi';
import TransactionApi from './TransactionApi';
import TreeApi from './TreeApi';
import BetsApi from './BetsApi';
import CashierJackpotApi from './CashierJackpotApi';
import SlotsBetHistoryApi from './SlotsBetHistoryApi';

class Api {
  constructor(baseUrl = '') {
    this.auth = new AuthApi(baseUrl);
    this.user = new UserApi(baseUrl);
    this.total = new TotalApi(baseUrl);
    this.totalBalance = new TotalBalanceApi(baseUrl);
    this.transaction = new TransactionApi(baseUrl);
    this.tree = new TreeApi(baseUrl);
    this.bets = new BetsApi(baseUrl);
    this.cashierJackpot = new CashierJackpotApi(baseUrl);
    this.slotsBetHistory = new SlotsBetHistoryApi(baseUrl);
  }
}

export default Api;
