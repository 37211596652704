import React, { useState, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Input from 'components/Input/desktop';
import Button from 'components/Button';

import { actions as usersActions } from 'features/users/redux';

import './CreateUser.scss';

const b = block('create-user');

const CreateUser = ({ history }) => {
  const dispatch = useDispatch();

  const [username, changeUsername] = useState('');
  const [password, changePassword] = useState('');

  const role = useSelector(state => state.auth.role);
  const locale = useSelector(state => state.locale.locale);
  const actionProcessing = useSelector(state => state.users.actionProcessing);

  const onClick = useCallback(() => {
    if (!actionProcessing) dispatch(usersActions.createUser(username, password, userId => history.replace('/')));
  }, [actionProcessing, dispatch, password, history]);

  let headerText = locale[`newAgent${role}`];

  return (
    <article className={b()}>
      <div className={b('content')}>
        <h1 className={b('sub-title')}>{headerText.split(' - ')[1]}</h1>
        <div className={b('field')}>
          <div className={b('field-title')}>{`${locale.username}:`}</div>
          <div className={b('field-input')}>
            <Input
              placeholder={locale.username}
              value={username}
              onChange={e => changeUsername(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className={b('field')}>
          <div className={b('field-title')}>{`${locale.password}:`}</div>
          <div className={b('field-input')}>
            <Input
              placeholder={locale.password}
              value={password}
              onChange={e => changePassword(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className={b('button')}>
          <Button onClick={onClick}>
            <span className={b('button-text')}>{locale.createAccount}</span>
          </Button>
        </div>
      </div>
    </article>
  );
};

export default withRouter(CreateUser);
