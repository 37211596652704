/* eslint-disable no-continue */
import React, { useMemo } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';

import ArrowSVG from './img/arrow.svg';

import './Paginator.scss';

const b = block('paginator-desktop');

const Paginator = ({ count, currentPage, onPageClick }) => {
  return (
    <div className={b()}>
      <SVGInline
        className={b('arrow').toString()}
        svg={ArrowSVG}
        onClick={currentPage === 0 ? null : () => onPageClick(currentPage - 1)}
      />
      <p className={b('page')}>{currentPage + 1}</p>
      <SVGInline
        className={b('arrow', { right: true }).toString()}
        svg={ArrowSVG}
        onClick={currentPage === count - 1 ? null : () => onPageClick(currentPage + 1)}
      />
    </div>
  );
};

Paginator.propTypes = {
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,

  onPageClick: PropTypes.func.isRequired,
};

export default Paginator;
