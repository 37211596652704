export const errorStatusMessage = {
  defaultMessage: 'Solicitud de ejecución fallida.',
  // auth
  1: 'Usuario no autorizado.',
  2: 'Email incorrecto.',
  3: 'Tal usuario no existe',
  4: 'Contraseña no corregida',
  5: 'El usuario está prohibido.',
  6: 'Clave de verificacion no valida.',
  7: 'Clave de verificación expirada.',
  8: 'El usuario ya ha pasado la verificación.',
  9: 'Solicitud de recuperación no encontrada.',
  10: 'La solicitud de recuperación ha expirado.',
  11: 'Solicitud de recuperación no encontrada.',
  14: 'Número de cambios arancelarios por mes superados',
  15: 'Numero de telefono invalido.',
  101: 'Solicitud de recuperación no encontrada.',
  102: 'Solicitud de recuperación no encontrada.',
  103: 'La transacción no se puede completar.',
  104: 'Error que muestra el estado de retiro de los fondos.',
  105: 'No puede retirar fondos.Las condiciones del programa de bonificación no se cumplen.Solicite el chat en línea.',
  106: 'La suma de la salida es menor que la mínima.',
  // Site
  201: 'No puedo conseguir plano.',
  202: 'No puedo obtener la lista de platera.',
  // Money
  501: 'Saldo insuficiente.',
  502: 'El usuario no existe.',
  // bet
  600: 'Bet no puede colocarse.',
  601: 'La apuesta no se encuentra.',
  602: 'La apuesta ya está comprada.',
  603: 'El evento ha terminado.',
  605: 'No puedes apostar.Saldo insuficiente',
  606: 'No puedes apostar.El porcentaje de bonificación no está permitido.',
  611: 'La apuesta no se encuentra.',
  612: 'El evento no se encuentra.',
  613: 'El evento ya comenzó.',
  614: 'Los coefs fueron cambiados.',
  615: 'Se cambiaron los tamaños de handicaps.',
  616: 'Error de ahorro+.',
  617: 'La apuesta está bloqueada por la casa de apuestas.',
  618: 'El evento ya se detuvo.',
  619: 'La cantidad de apuesta es más que el máximo.',
  620: 'Información sobre el autor de la apuesta no encontrada.',
  621: 'Información sobre el contenido de la apuesta no encontrada.',
  622: 'No hay dinero en la cuenta del reproductor.',
  623: 'La recepción veterinaria en el evento se detuvo.',
  624: 'La recepción veterinaria en la variante se detendrá.',
  625: 'La apuesta está vacía.',
  626: 'Número incorrecto de ordinario.',
  627: 'En el expreso o el sistema, el mismo evento se usa más de una vez.',
  628: 'Eventos imposibles.',
  629: 'El límite en la apuesta máxima en una línea habitual no se ha aprobado.',
  630: 'El límite en la apuesta máxima en una línea en vivo no se ha aprobado.',
  631: 'No se ha aprobado el límite en el amor de todas las apuestas en la línea habitual.',
  632: 'El límite en el amor de todas las apuestas colocadas en la línea en vivo no se ha aprobado.',
  633: 'El límite en el pago máximo en una línea habitual no se ha aprobado.',
  634: 'El límite en el pago máximo en una línea en vivo no se ha aprobado.',
  635: 'El límite en la apuesta máxima en una línea habitual no se ha aprobado.',
  636: 'El límite en la apuesta máxima en una línea en vivo no se ha aprobado.',
  637: 'No se ha aprobado el límite en el amor de todas las apuestas en la línea habitual.',
  638: 'El límite en el amor de todas las apuestas colocadas en la línea en vivo no se ha aprobado.',
  639: 'El límite en el pago máximo en una línea habitual no se ha aprobado.',
  640: 'El límite en el pago máximo en una línea en vivo no se ha aprobado.',
  641: 'El límite en la apuesta máxima en una línea habitual no se ha aprobado.',
  642: 'El límite en la apuesta máxima en una línea en vivo no se ha aprobado.',
  643: 'No se ha aprobado el límite en el amor de todas las apuestas en la línea habitual.',
  644: 'El límite en el amor de todas las apuestas colocadas en la línea en vivo no se ha aprobado.',
  645: 'El límite en el pago máximo en una línea habitual no se ha aprobado.',
  646: 'El límite en el pago máximo en una línea en vivo no se ha aprobado.',
  647: 'El límite en la apuesta máxima en una línea habitual no se ha aprobado.',
  648: 'El límite en la apuesta máxima en una línea en vivo no se ha aprobado.',
  649: 'No se ha aprobado el límite en el amor de todas las apuestas en la línea habitual.',
  650: 'El límite en el amor de todas las apuestas colocadas en la línea en vivo no se ha aprobado.',
  651: 'El límite en el pago máximo en una línea habitual no se ha aprobado.',
  652: 'El límite en el pago máximo en una línea en vivo no se ha aprobado.',
  653: 'El límite en la apuesta máxima en una línea habitual no se ha aprobado.',
  654: 'El límite en la apuesta máxima en una línea en vivo no se ha aprobado.',
  655: 'No se ha aprobado el límite en el amor de todas las apuestas en la línea habitual.',
  656: 'El límite en el amor de todas las apuestas colocadas en la línea en vivo no se ha aprobado.',
  657: 'El límite en el pago máximo en una línea habitual no se ha aprobado.',
  658: 'El límite en el pago máximo en una línea en vivo no se ha aprobado.',
  659: 'No se ha aprobado el límite en el pago máximo de BET en una línea habitual.',
  660: 'No se ha aprobado el límite en el pago máximo de Bet in BET en una línea en vivo.',
  661: 'No se ha aprobado el límite en el pago máximo de Bet en el evento en una línea habitual.',
  662: 'No se ha aprobado el límite en el pago máximo de apuesta en el evento en una línea en vivo.',
  663: 'No se ha aprobado el límite en el pago máximo de BET en Tourney en una línea habitual.',
  664: 'No se ha aprobado el límite en el pago máximo de BET en Tourney en una línea en vivo.',
  665: 'No se ha aprobado el límite en el pago máximo de BET por parte del usuario en una línea habitual.',
  666: 'No se ha aprobado el límite en el pago máximo de la apuesta por parte del usuario en una línea en vivo.',
  667: 'No se ha aprobado el límite en el pago máximo de Bet in Sport por parte del usuario en una línea habitual.',
  668: 'No se ha aprobado el límite en el pago máximo de Bet in Sport por parte del usuario en una línea en vivo.',
  669: 'No se encuentra el usuario.',
  670: 'No se encuentra el punto.',
  671: 'No se encuentra el torneo.',
  672: 'El contenido de apuesta no se encuentra.',
  673: 'BET es en vivo, el evento no es en vivo.',
  674: 'El evento es en vivo, BET no está en vivo.',
  675: 'El coef de dinero es malo.',
  676: 'Un usuario puso más de una apuesta en una unidad de tiempo.',
  677: 'La apuesta mínima es mayor que la apuesta máxima.',
  678: 'No se encuentra a la expresión superior.',
  679: 'Se excedió el monto legal máximo de pago por apuesta.',
  680: 'El evento está solo en Express.',
  681: 'El evento está en la sola apuesta.',
  682: 'Mal número de ordinarios en el Express (Triple Express).',
  683: 'Mal número de ordinarios en el Express (Quadruple Express).',
  684: 'Mal número de ordinarios en el expreso (expreso quinario).',
  685: 'Mal número de ordinarios en el Express (Senary Express).',
  686: 'Mal número de ordinarios en el Express (expreso que contiene siete apuestas).',
  687: 'Mal número de ordinarios en el Express (expreso que contiene ocho apuestas).',
  688: 'Mal número de ordinarios en el Express (expreso que contiene nueve apuestas).',
  689: 'Se excedió el tamaño del COEF máximo en el expreso.',
  690: 'El monto de la apuesta excede el límite actual del punto.',
  691: 'El límite para el número de esta apuesta para este usuario.',
  692: 'Tipo de software inaceptable, este software no puede apostar.',
  693: 'Moneda inaceptable.',
  694: 'Se excedió el límite para colocar apuestas.',
  999: 'Error upprove.',
  // Promocode
  2200: 'El promocodo no se encuentra.',
  2201: 'El Promocode ha expirado.',
  2202: 'El código promocional individual ya se ha activado.',
  2203: 'Ya ha activado este código de promoción.',
  2204: 'Estás tratando de activar el código promocional de otro socio.',
  2205: 'Estás tratando de activar tu propio código promocional.',
  2206: 'Tipo de promoción desconocido.',
  2207: 'Ya ha activado este porcentaje de promoción.',
  2208: 'Ya ha activado Promocode.',
  2209: 'El usuario ya ha cancelado el código promocional.',
  2210: 'El usuario ya ha llenado el código promocional antes.',
  2211: 'El usuario no es miembro del programa de bonificación.',
  2212: 'Promoode fue bloqueado por Admin.',
  2213: 'El usuario tiene retiros después de la activación de promociones.',
  2214: 'El usuario no está satisfecho con los términos del programa de bonificación.',
};
