import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export class TransactionApiConverter {
  convertTransfers = data => ({
    transactions:
      data.data
        ?.map(t => ({
          amount: t.amount,
          date: dayjs(t.date, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY'),
          balance: t.balance,
          email: t.email,
        }))
        .sort((a, b) => (dayjs(b.date, 'YYYY-MM-DD HH:mm').isBefore(a.date) ? -1 : 1)) || [],

    total_deposit: data.total_deposit,
    total_withdrawal: data.total_withdrawal,
    itemsCount: data.total_count,
  });
}
