import React, { useEffect, useState, useMemo, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

import { actions as usersActions } from 'features/users/redux';
import { formatNumberWithLimit } from 'shared/utils/formatNumber';

import InputSearch from 'components/InputSearch';
import SVG from 'components/SVG';
import Button from 'components/Button';

import arrowSvg from './img/arrow.svg';

import './UsersList.scss';
import Paginator from 'components/Paginator/desktop';

const b = block('users-list');
const itemsOnPage = 30;

const UsersList = () => {
  const dispatch = useDispatch();

  const [filterValue, onChangeFilterValue] = useState('');
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(usersActions.getUsersList());
  }, [dispatch]);

  const changeFilterValue = useCallback(e => {
    onChangeFilterValue(e.currentTarget.value);
  }, []);

  const handleFromSubmit = useCallback(
    e => {
      e.preventDefault();
      setItems(
        usersList.users?.filter(
          t => t?.nickname.toUpperCase().includes(filterValue.toUpperCase()) || String(t?.id).includes(filterValue),
        ),
      );
    },
    [filterValue],
  );

  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const usersList = useSelector(state => state.users.usersList, shallowEqual);

  useEffect(() => {
    setItems(usersList.users);
    onChangeFilterValue('');
  }, [usersList]);

  const pageCount = Math.ceil(items.length / itemsOnPage);

  const list = useMemo(() => items
    .slice(page * itemsOnPage, (page + 1) * itemsOnPage)
    .map(item => (
      <Link key={item.id} className={b('item')} to={`/user-edit/${item.id}`}>
        <div className={b('item-text')}>{`${item.nickname} (ID: ${item.id})`}</div>
        <div className={b('item-balance')}>{formatNumberWithLimit(item.balance)}</div>
        <SVG svgProps={{ svg: arrowSvg }} className={b('item-arrow')} />
      </Link>
    )) || [],
  [filterValue, items, page]);

  return (
    <article className={b()}>
      <h1 className={b('title')}>{locale.userCardBalances}</h1>

      <form className={b('filter')} onSubmit={handleFromSubmit}>
        <div className={b('search-wrapper')}>
          <span className={b('label')}>{locale.search}</span>

          <div className={b('search-input')}>
            <InputSearch value={filterValue} onChange={changeFilterValue} placeholder={locale.search} />
          </div>
        </div>

        <div className={b('button-wrapper')}>
          <Button type="submit">
            <div className={b('button')}>{locale.showResults}</div>
          </Button>
        </div>

        <div className={b('subheader-data-item')}>
          <div className={b('subheader-data-item-nick')}>{locale.userCard}</div>
          <div className={b('subheader-data-item-value')}>{usersList.quantity}</div>
        </div>

        <div className={b('subheader-data-item')}>
          <div className={b('subheader-data-item-nick')}>{locale.accountBalance}</div>
          <div className={b('subheader-data-item-value')}>{formatNumberWithLimit(usersList.totalBalance)}</div>
        </div>
      </form>

      <div className={b('list')}>{list}</div>

      <div className={b('paginator')}>
        <Paginator
          count={pageCount}
          currentPage={page}
          onPageClick={setPage}
        />
      </div>
    </article>
  );
};

export default UsersList;
