import React, { useState, useCallback, useMemo, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

import { actions as transactionActions } from 'features/transaction/redux';
import { formatNumberWithLimit } from 'shared/utils/formatNumber';

import Button from 'components/Button';
import InputDate from 'components/InputDate';
import Paginator from 'components/Paginator/desktop';

import './TransactionHistory.scss';

const b = block('transaction-history');
const itemsOnPage = 30;

const TransactionHistory = () => {
  const dispatch = useDispatch();
  const [filterValue, onChangeFilterValue] = useState('');
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { transactions, itemsCount } = useSelector(
    state => state.transaction,
    shallowEqual,
  );

  const [startDate, setStartDate] = useState(dayjs(new Date()).add(-3, 'day').$d);
  const [endDate, setEndDate] = useState(dayjs(new Date()).$d);
  const [page, setPage] = useState(0);

  const changeFilterValue = useCallback(e => {
    onChangeFilterValue(e.currentTarget.value);
  }, []);

  const onClick = useCallback(() => {
    dispatch(transactionActions.getTransactions({ startDate, endDate, page, count: itemsOnPage }));
  }, [dispatch, endDate, startDate, page]);

  useEffect(() => {
    onClick()
  }, [page]);

  const list = useMemo(
    () =>
      transactions
        .map((item, index) => (
          <div className={b('row')} key={index}>
            <span className={b('td')}>{item.email}</span>
            <span className={b('td')}>{item.date}</span>
            <span className={b('td')}>{formatNumberWithLimit(item.amount)}</span>
            <span className={b('td')}>{item.balance}</span>
          </div>
        )) || [],
    [transactions],
  );

  const pageCount = Math.ceil(itemsCount / itemsOnPage);

  return (
    <div className={b()}>
      <h3 className={b('title')}>{locale['/transaction-history']}</h3>
      <div className={b('content')}>
        <div className={b('fields')}>
          <div className={b('field-title')}>{`${locale.from}`}</div>
          <div className={b('field-title')}>{`${locale.to}`}</div>
          <div />
          <div className={b('field-input')}>
            <InputDate
              dateFormat="yyyy-MM-dd"
              selected={new Date(startDate)}
              onChange={date => {
                setStartDate(dayjs(date).format('YYYY-MM-DD'));
              }}
              isValid={Boolean(startDate)}
              title="date title"
            />
          </div>
          <div className={b('field-input')}>
            <InputDate
              dateFormat="yyyy-MM-dd"
              selected={new Date(endDate)}
              onChange={date => {
                setEndDate(dayjs(date).format('YYYY-MM-DD'));
              }}
              isValid={Boolean(endDate)}
              title="date title"
            />
          </div>
          <div className={b('button')}>
            <Button onClick={onClick}>{locale.display}</Button>
          </div>
        </div>
      </div>

      <div className={b('result-wrapper')}>
        <div className={b('result-header')}>
          <span className={b('th')}>{locale.email}</span>
          <span className={b('th')}>{locale.date}</span>
          <span className={b('th')}>{locale.amount}</span>
          <span className={b('th')}>{locale.balance}</span>
        </div>
        <div className={b('items')}>{list}</div>
      </div>

      <div className={b('paginator-container')}>
        <div className={b('paginator')}>
          <Paginator count={pageCount} currentPage={page} onPageClick={setPage} />
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
