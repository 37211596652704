import BaseApi from '../BaseApi';
import { TransactionApiConverter } from './TransactionApiConverter';

class TransactionApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.converter = new TransactionApiConverter();
  }

  createUser = (userName, password, currency) =>
    this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/create_user`, {
      email: userName,
      password,
      name: '',
      currency,
    });

  getTransactions = data =>
    this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/transfers`,
      data,
      null,
      this.converter.convertTransfers,
    );
}

export default TransactionApi;
